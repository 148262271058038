import * as React from "react"
import { useState } from "react"

const Layout = ({ title, children }) => {

  const [isActive, setActive] = useState("false")
  const ToggleClass = () => {
    setActive(!isActive)
  }
  
  return (
    <div>
      <div className={isActive ? "hidden" : "bg-black"}>
        <div class="p-4 md:p-10 max-w-screen-2xl mx-auto text-sm md:text-base text-white">
          <p>
            Aggregated articles from design teams. Browse, search for
            keywords or companies, add it to an rss reader.{" "}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="mailto:rob+designteamdotblog@robedwards.org"
            >
              Send me an email
            </a>{" "}
            if you like it or have a suggestion.
          </p>
        </div>
      </div>
      <div className="p-4 md:p-10 max-w-screen-2xl mx-auto">
        <header className="mt-4 md:mt-12">
          <h1 className="text-4xl md:text-6xl mb-1 md:mb-3">
            Design Team Dot Blog
          </h1>
          <div className="md:flex justify-between mb-3">
            <p className="md:text-xl mb-2">
              Articles from prominent design teams
            </p>
            <nav className="mb-3 text-sm md:text-base">
              <a href="#" onClick={ToggleClass}>Help</a>
              <a href="/rss.xml">RSS</a>
              {/* <a href="https://www.buymeacoffee.com/robedwards">Coffee</a> */}
            </nav>
          </div>
          <div className="h-3 md:h-6 bg-black w-full mb-3 md:mb-6"></div>
        </header>
        <main>{children}</main>
      </div>
    </div>
  )
}

export default Layout
